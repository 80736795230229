import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "map" }
const _hoisted_2 = { class: "map__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_g_map_marker = _resolveComponent("g-map-marker")!
  const _component_g_map_info_window = _resolveComponent("g-map-info-window")!
  const _component_g_map_map = _resolveComponent("g-map-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_g_map_map, {
        center: {lat: Number($setup.props.lat), lng: Number($setup.props.long)},
        zoom: 10,
        lang: $setup.props.lang,
        "map-type-id": "terrain",
        style: {"width":"100%","height":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_g_map_marker, {
            position: {lat: Number($setup.props.lat), lng: Number($setup.props.long)},
            icon: '/static/app/map-marker.svg'
          }, null, 8, ["position", "icon"]),
          _createVNode(_component_g_map_info_window)
        ]),
        _: 1
      }, 8, ["center", "lang"])
    ])
  ]))
}