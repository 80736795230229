export const HQ_VPN_ACCESS_TOKEN_STORAGE_NAME = 'HQ_VPN:ACCESS_TOKEN_STORAGE'
export const HQ_VPN_REFRESH_TOKEN_STORAGE_NAME = 'HQ_VPN:REFRESH_TOKEN_STORAGE'
export const HQ_VPN_DEVICE_UUID_STORAGE_NAME = 'HQ_VPN:DEVICE_UUID_STORAGE'

export const HQ_VPN_LANGUAGE_COOKIE_NAME = 'HQ_VPN:CHOSEN_LANGUAGE'

export const HQ_VPN_CURRENT_PAYMENT_ID_NAME = 'HQ_VPN:CURRENT_PAYMENT_ID'
export const HQ_VPN_CURRENT_PAYMENT_URL_NAME = 'HQ_VPN:CURRENT_PAYMENT_URL'

export const HQ_USER_SAVED_NAME = 'HQ_VPN:USER_SAVED_EMAIL'
export const HQ_VPN_IS_AUTH_STORAGE_NAME = 'HQ_VPN:IS_AUTH_STORAGE_NAME'

export const HQ_DEMO_ACCESS_MODAL_CHECK_NAME = 'HQ_DEMO_ACCESS_MODAL_CHECK'
export const HQ_REFERENCE_COOKIE_NAME = 'HQ_REFERENCE_COOKIE_NAME'
