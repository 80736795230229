import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hq-modal" }
const _hoisted_2 = { class: "hq-modal__wrapper" }
const _hoisted_3 = { class: "hq-modal__title" }
const _hoisted_4 = { class: "text ds-caption ds-caption--size_lg ds-caption--size_5xl-lg ds-caption--color_dark-blue ds-caption--bold ds-caption--appearance_center" }
const _hoisted_5 = { class: "hq-modal__subtitle" }
const _hoisted_6 = { class: "text ds-caption ds-caption--size_xs ds-caption--size_2md-lg ds-caption--color_dark-blue ds-caption--medium ds-caption--appearance_center" }
const _hoisted_7 = { class: "hq-modal__btn" }
const _hoisted_8 = { class: "hq-btn__text ds-caption ds-caption--color_white ds-caption--bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString($setup.$t('Смена email')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString($setup.$t('На указанный вами Email, было  отправлено письмо с инструкциями для подтверждения нового email адреса.у')), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: "hq-btn hq-btn--blue hq-btn--centered hq-btn--br-radius_main hq-btn--full_width",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$attrs.close()))
        }, [
          _createElementVNode("p", _hoisted_8, _toDisplayString($setup.$t('Ок')), 1)
        ])
      ])
    ])
  ]))
}