import { createApp, App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Components from './components/'
import Modules from './modules'
import { onScroll } from './modules/helpers/scroll'
import { checkProviderWidth } from './modules/helpers/provider' 
import { viewportFix } from './modules/helpers/viewport-fix'
import { scroller } from './utils/scroller'
import { openDemoModal } from './utils/demo-access-modal-trigger'
import Directives from './directives'
import { storage } from '@/utils/session'
import {
  HQ_VPN_ACCESS_TOKEN_STORAGE_NAME,
  HQ_VPN_IS_AUTH_STORAGE_NAME,
  HQ_REFERENCE_COOKIE_NAME,
  HQ_DEMO_ACCESS_MODAL_CHECK_NAME,
} from '@/const'

import './styles/index.sass'

import {
  getCookie,
  setCookie,
} from './utils/cookies'

import CabinetView from '@/components/subscriptions/CabinetView.vue'
import Cabinet from '@/components/subscriptions/UserCabinet.vue'
import Login from '@/components/forms/AuthForm.vue'

import {
  prefixLanguage,
} from '@/utils/urls.js'

const routes = [
  {
    path: prefixLanguage('/ru/cabinet/'),
    component: CabinetView,
    name: 'cabinetView',
    redirect: storage.getItem(HQ_VPN_IS_AUTH_STORAGE_NAME) ? prefixLanguage('/ru/cabinet/personal/'): prefixLanguage('/ru/cabinet/permission-denied/'),
    children: [
      {
        path: prefixLanguage('/ru/cabinet/personal/'),
        component: Cabinet,
        name: 'cabinet',
        meta: { logout: true },
      },
      {
        path: prefixLanguage('/ru/cabinet/permission-denied/'),
        component: Login,
        name: 'login',
      },
    ],
  },
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

const LEAVE_SITE_TIMEOUT = 30000
export const VPN_LEAVE_SITE_VIEW = 'VPN:LEAVE_SITE_VIEW'
export const VPN_LEAVE_SITE_TIME = 'VPN:LEAVE_SITE_TIME'

const app: App = createApp({
  delimiters: ['[[', ']]'],
  data() {
    return {
      date: new Date(),
      True: true,
      False: false,
      None: undefined,
    }
  },
  methods: {
    checkTimes(interval) {
      const timestamp = (new Date()).getTime()
      const timestampCookie = getCookie(VPN_LEAVE_SITE_TIME)
      if ((timestamp - timestampCookie) > LEAVE_SITE_TIMEOUT) {
        this.openModal(this)
        clearInterval(interval)
        return
      }
      clearInterval(interval)
    },
    openModal() {
      const isAuth = getCookie(HQ_VPN_ACCESS_TOKEN_STORAGE_NAME)
      const show = getCookie(HQ_DEMO_ACCESS_MODAL_CHECK_NAME)
      if(!isAuth) {
        if(!show) {
          openDemoModal()
          setCookie(HQ_DEMO_ACCESS_MODAL_CHECK_NAME, true)
        }
      }
    },
    onLeave() {
      const showMobileAppsModal = getCookie(VPN_LEAVE_SITE_VIEW)
      const timestampCookie = getCookie(VPN_LEAVE_SITE_TIME)
      if (!timestampCookie) setCookie(VPN_LEAVE_SITE_TIME, (new Date()).getTime())
      if (showMobileAppsModal) return
      let int = null
      int = setInterval(() => {
        this.checkTimes(int)
      }, 2000)
    }
  }
}
)

declare global {
  interface Window {
    language: ''; // 👈️ turn off type checkingw
    userEmail: '',
    loginPage: '',
    dataLayer: any[],
    isAnalyticEnabled: false,
  }
}


const REF_GET_PAPAM_KEY = 'ref='

const refGetter = () => {
  const [, ref] = window.location.search.split(REF_GET_PAPAM_KEY)
  if (ref) {
    setCookie(HQ_REFERENCE_COOKIE_NAME, ref)
  }
}

document.addEventListener("DOMContentLoaded", function(event) {
  onScroll()
  viewportFix()
  checkProviderWidth()
  scroller()
  refGetter()
})

export {
  emailErrMessage,
  requiredErrMessage,
  confirmedErrMessage,
  minErrMessage,
}

app.use(Modules)

Components.installComponents(app)
Directives.registerDirective(app)


const { $t } = app.config.globalProperties

const requiredErrMessage = $t('This field is required')
const emailErrMessage = $t('This field must be a valid email')
const confirmedErrMessage = $t('Passwords must match')
const minErrMessage = $t('Min')

app.use(router)
app.mount('#app')