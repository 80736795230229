import { App } from 'vue'
import IEye from './i-eye.vue'
import IEyeOff from './i-eye-off.vue'

const register = (app: App<Element>): void => {
  app
  .component('IEye', IEye)
  .component('IEyeOff', IEyeOff)
}

export default {
  register,
}
