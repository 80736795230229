/* eslint-disable */
import {
  getOrCreateDeviceUuid,
} from '@/utils/uuid'
import {
  getOs,
} from '@/utils/os'

const application_type: string = 'native-application'

export default function useDeviceInfo(): { device: { application_type: string, os: string|null, key: string|null }} {
  const os: string | null =  getOs()
  const key: string | null = getOrCreateDeviceUuid()
  return {
    device: {
      key,
      os,
      application_type,
    },
  }
}