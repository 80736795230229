<template lang="pug">
include /mixins.pug
+b.modal
  button.close(type='button' @click="checkHandler")
    IClose
  +e.wrapper
    +e.body
      +b.P.ds-caption.--color_main-dark.--size_3md.--size_lg-lg.--semibold.--appearance_center.ec-space_pt--6.--4-lg {{ message.title }}
      +b.P.ds-caption.--color_main-dark.--size_md.--regular.--appearance_center.ec-space_pt--5(v-if="message.text") {{ message.text }}
      +e.icon(v-if="message.icon")
        component(
          :is="message.icon"
        )

</template>

<script setup>
import { defineProps } from 'vue'

/* eslint-disable */
const props = defineProps({
  close: Function,
  message: Object,
  reload: Boolean,
})

const checkHandler = () => props.reload ? window.location.reload(): props.close()

</script>
