import { App } from 'vue'
import EmailChangeModal from './EmailChangeModal.vue'
import KeyGenModal from './KeyGenModal.vue'
import QrModal from './QrModal.vue'
import TrialAccessModal from './TrialAccessModal.vue'

const register = (app: App<Element>): void => {
  app
  .component('EmailChangeModal', EmailChangeModal)
  .component('KeyGenModal', KeyGenModal)
  .component('TrialAccessModal', TrialAccessModal)
  .component('QrModal', QrModal)

}

export default {
  register,
}
