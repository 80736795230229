import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-control-input d-control-input--appearance_bordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_multiselect = _resolveComponent("vue-multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_multiselect, _mergeProps({ class: "d-control-input__element" }, _ctx.$attrs, {
      options: $props.options,
      label: $props.labelBy,
      "track-by": $props.labelBy,
      modelValue: $data.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.model) = $event))
    }), _createSlots({ _: 2 }, [
      _renderList($options.eSlots, (name, key) => {
        return {
          name: name,
          fn: _withCtx((binded) => [
            _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(binded)))
          ])
        }
      })
    ]), 1040, ["options", "label", "track-by", "modelValue"])
  ]))
}