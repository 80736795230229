export function onScroll(){
  const logo = document.getElementById('header-logo')
  const buttons = document.getElementById('buttons-block')
  const header = document.getElementById('header')
  const headerWrap = document.getElementById('header-wrap')
  const headerProvider = document.getElementById('provider_wrapper_js') 
  const langSelect = document.getElementById('lang-select_js')
  const curUrl = header.dataset['url']
  const windowHeightNow = window.pageYOffset

  if('/404' == curUrl || '/403' == curUrl) {
    setHeaderPosition()
    return
  }

  function setHeaderPosition() {
    logo && logo.classList.remove('disabled-xl')
    header && header.classList.add('header--offset_main')
    headerWrap && headerWrap.classList.add('header-wrapper--offset_main')
    buttons && buttons.classList.remove('buttons-block--variant_main')
    headerProvider && headerProvider.classList.add('provider-disabled')
    langSelect && langSelect.classList.add('lang-select-special')
  }

  function unsetHeaderPosition() {
    logo && logo.classList.add('disabled-xl')
    header && header.classList.remove('header--offset_main')
    headerWrap && headerWrap.classList.remove('header-wrapper--offset_main')
    buttons && buttons.classList.add('buttons-block--variant_main')
    headerProvider && headerProvider.classList.remove('provider-disabled')
    langSelect && langSelect.classList.remove('lang-select-special')

  }

  if(windowHeightNow >= 1) {
    setHeaderPosition()
  } else {
    unsetHeaderPosition()
  }

  document.addEventListener('scroll', function(e) {
    const windowHeight = window.pageYOffset
    if(windowHeight >= 1) {
      setHeaderPosition()
    } else {
      unsetHeaderPosition()
    }
  })
}