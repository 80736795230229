import { storage } from '@/utils/session'
import {
  HQ_VPN_ACCESS_TOKEN_STORAGE_NAME,
  HQ_VPN_REFRESH_TOKEN_STORAGE_NAME,
} from '@/const'

export default async function useLogout() {
  return new Promise(resolve => {
    storage.removeItem(HQ_VPN_ACCESS_TOKEN_STORAGE_NAME)
    storage.removeItem(HQ_VPN_REFRESH_TOKEN_STORAGE_NAME)
    resolve(true)
  })
}