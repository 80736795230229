import { App } from 'vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import UiElements from './UiElements'
import Forms from './forms'
import Modals from './modals'
import Controls from './controls'
import Elements from './elements'
import Subscriptions from './subscriptions'
import AuthComponents from './auth'
import I from './i'

const installComponents = (app: App<Element>): void => {
  UiElements.register(app)
  Forms.register(app)
  Modals.register(app)
  Controls.register(app)
  Elements.register(app)
  Subscriptions.register(app)
  AuthComponents.register(app)
  app.component(VueCountdown.name, VueCountdown)
  I.register(app)
}

export default {
  installComponents,
}
