/* eslint-disable */
import {
  prefixLanguage,
} from '@/utils/urls'

// префикс апи
export const API_BASE_URL = '/api/v1/'

const PROD_SERVER = 'hqvpn.net'

const API_TEST_EXTERNAL_URL = 'https://hq-vpn-main.webcase-dev.com/api/v2/'
const API_PROD_EXTERNAL_URL = 'https://hqconsole.com/api/v2/'

export const API_EXTERNAL_URL = window.location.host == PROD_SERVER ? API_PROD_EXTERNAL_URL : API_TEST_EXTERNAL_URL
// export const API_EXTERNAL_URL = window.externalApi

export const prefixAPI = (url, model = '', base = API_BASE_URL) => base + model + url
