import { App } from 'vue'
import TryDemoAccessForm from './TryDemoAccessForm.vue'
import CallBackForm from './CallBackForm.vue'
import AuthForm from './AuthForm.vue'
import PassResetForm from './PassResetForm.vue'
import PassSetForm from './PassSetForm.vue'
import TestAccessForm from './TestAccessForm.vue'
import EmailChangeForm from './/EmailChangeForm.vue'
import SubsriptionForm from './SubsriptionForm.vue'


const register = (app: App<Element>): void => {
  app
  .component('TryDemoAccessForm', TryDemoAccessForm)
  .component('AuthForm', AuthForm)
  .component('PassResetForm', PassResetForm)
  .component('PassSetForm', PassSetForm)
  .component('TestAccessForm', TestAccessForm)
  .component('SubsriptionForm', SubsriptionForm)
  .component('EmailChangeForm', EmailChangeForm)
  .component('CallBackForm', CallBackForm)

}

export default {
  register,
}
