import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'
import { storage } from '@/utils/session'
import {
  HQ_VPN_ACCESS_TOKEN_STORAGE_NAME,
} from '@/const'

export const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
  'Authorization': `Bearer ${storage.getItem(HQ_VPN_ACCESS_TOKEN_STORAGE_NAME)}`,
  'Accept-Language': window.language,
}))
