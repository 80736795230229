import { App } from 'vue'
import { tableWrapper } from './table'

const registerDirective = (app: App<Element>): void => {
  app
    .directive('table-wrapper', tableWrapper)
}

export default {
  registerDirective,
}
