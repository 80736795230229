import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-form auth-form--bordered auth-form--full_width-xl" }
const _hoisted_2 = { class: "auth-form__title" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_3xl-xl ds-caption--medium ds-caption--color_dark-blue ds-caption--appearance_center" }
const _hoisted_4 = { class: "ds-panel ds-panel--space_4xl" }
const _hoisted_5 = { class: "ds-panel__element ds-panel__element--offset_vertical" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_lg g-row--space_none-xl" }
const _hoisted_7 = { class: "error-label" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--12 g-cols--8-xl" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--12 g-cols--4-xl layer_up" }
const _hoisted_10 = {
  class: "hq-btn hq-btn--callback-form-style hq-btn--centered hq-btn--full_width hq-btn--full_height-xl",
  type: "submit"
}
const _hoisted_11 = { class: "hq-btn__text ds-caption ds-caption--color_white ds-caption--bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_vee_form = _resolveComponent("vee-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t("Ваша персональная информация под защитой и нужна нам только чтобы предоставить вам качественный сервис")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_vee_form, {
          class: "hq-form",
          onSubmit: $setup.request
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
                default: _withCtx(({ message }) => [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(message), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_d_control_input, {
                  class: "input-simple-main callback-style callback-style--second",
                  rules: "required|email",
                  name: "key",
                  "input-label": "",
                  modelValue: _ctx.key,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.key) = $event)),
                  placeholder: $setup.$t("Введите ваш e-mail")
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", _hoisted_10, [
                  _createElementVNode("p", _hoisted_11, _toDisplayString($setup.$t("Далее")), 1)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}