import { App } from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const lang = window.language as string
/* eslint-disable */
export default {
  install(app: App<Element>) {
    app.use(VueGoogleMaps, {
      load: {
          key: 'AIzaSyBC5IEA-ppQ4SoSwRusatpOXXYOaMxgw_M',
          language: lang,
      }
    })
  }
}
