import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "call-back-block call-back-block--offet_sm" }
const _hoisted_2 = { class: "call-back-block__title" }
const _hoisted_3 = {
  key: 0,
  class: "ds-caption ds-caption--size_lg ds-caption--size_5xl-xl ds-caption--bold ds-caption--color_white ds-caption--appearance_center"
}
const _hoisted_4 = {
  key: 1,
  class: "ds-caption ds-caption--size_lg ds-caption--size_5xl-xl ds-caption--bold ds-caption--color_white ds-caption--appearance_center"
}
const _hoisted_5 = {
  key: 2,
  class: "ds-caption ds-caption--size_lg ds-caption--size_5xl-xl ds-caption--bold ds-caption--color_white ds-caption--appearance_center"
}
const _hoisted_6 = { class: "call-back-block__form" }
const _hoisted_7 = { class: "g-row g-row--appearance_spaced g-row--space_lg g-row--space_none-xl g-row--justify_center g-row--align_center" }
const _hoisted_8 = { class: "error-label" }
const _hoisted_9 = {
  key: 0,
  class: "g-cell g-cols g-cols--12 g-cols--8-xl"
}
const _hoisted_10 = { class: "g-cell g-cols g-cols--12 g-cols--4-xl layer_up" }
const _hoisted_11 = {
  key: 0,
  class: "hq-btn hq-btn--callback-form-style hq-btn--centered hq-btn--full_width hq-btn--full_height-xl",
  type: "submit"
}
const _hoisted_12 = { class: "hq-btn__text ds-caption ds-caption--size_sm ds-caption--size_2md-xl ds-caption--color_white ds-caption--bold" }
const _hoisted_13 = {
  key: 1,
  class: "g-row g-row--appearance_spaced g-row--space_lg g-row--space_xl-xl g-row--justify_center g-row--appearance_column"
}
const _hoisted_14 = {
  key: 0,
  class: "g-cell g-cols g-cols--12 g-cols--narrow-xl"
}
const _hoisted_15 = {
  class: "hq-btn hq-btn--blue hq-btn--centered hq-btn--auth-style hq-btn--full_width hq-btn--full_height-xl",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_vee_form = _resolveComponent("vee-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.isLoad && $setup.isSended)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.$t("Заявка принята, в ближайшее время с вами свяжется наш менеджер.")), 1))
        : ($setup.isLoad && !$setup.isSended)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.$t("Что-то пошло не так, говорят нет такой почты...")), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($setup.$t("Не удалось установить приложение? Оставьте пожалуйста свой email и наша служба поддержки свяжется с вами")), 1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_vee_form, {
        class: "hq-form",
        onSubmit: $setup.request
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_8, _toDisplayString(message), 1)
              ]),
              _: 1
            }),
            (!$setup.isLoad && !$setup.isSended)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_d_control_input, {
                    class: "input-simple-main callback-style",
                    rules: "required|email",
                    name: "email",
                    "input-label": "",
                    modelValue: $setup.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
                    placeholder: $setup.$t("Введите ваш e-mail")
                  }, null, 8, ["modelValue", "placeholder"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              (!$setup.isLoad && !$setup.isSended)
                ? (_openBlock(), _createElementBlock("button", _hoisted_11, [
                    _createElementVNode("p", _hoisted_12, _toDisplayString($setup.$t("Отправить запрос")), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (!$setup.isSended)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("button", _hoisted_15, [
                            _createElementVNode("p", {
                              class: "hq-btn__text ds-caption ds-caption--color_white ds-caption--bold",
                              onClick: _cache[1] || (_cache[1] =  () => { $setup.isLoad = false; $setup.isSended = false })
                            }, _toDisplayString($setup.$t("Ввести другую")), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}