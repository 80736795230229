<template lang="pug">
include /mixins
+b.pass-gen
  +b.content-block_main.--space-sm.--password
    +b.g-row.--justify_center.--justify_start-xl.--appearance_spaced.--space_md.--space_2xl-xl
      +b.g-cell.g-cols--12.--9-xl
        +b-context("pass-gen-block") 
          +e.title
            +b.P.ds-caption.--medium.--size_2md.--size_4xls-xl.--color_dark-blue {{ $t("Генератор паролей поможет Вам создать надежный пароль. Заполните форму и нажмите кнопку Сгенерировать.") }}
      +b.g-cell.g-cols--12.--7-xl
        +b.pass-gen-block
          +e.length
            +e.title
              +b.P.ds-caption.--bold.--size_md.--color_dark-blue {{ $t("Длинна пароля") }}
            +e.length-selector
              +b.g-row.--align_center.--justify_center-sm.--justify_between-xl.--appearance_spaced.--space_md
                  +b.g-cell.g-cols--12.--narrow-sm.--auto-xl
                    +b.g-row.--align_center.--justify_between.--justify_center-sm.--justify_between-xl.--appearance_spaced.--appearance_nowrap.--space_md
                      template(v-for="(item, index) in length")
                        +b.g-cell.g-cols.pass-length(v-if="5 > index")
                          label(
                            :for="item.value"
                          )
                            input(
                              class="length-radio"
                              type="radio" 
                              :id="item.value"
                              :value="item.value"
                              v-model="gLength")
                            +b.length-label {{ item.value }}
                  +b.g-cell.g-cols--12.--narrow-sm.--auto-xl
                    +b.g-row.--align_center.--justify_between.--justify_center-sm.--justify_between-xl.--appearance_spaced.--appearance_nowrap.--space_md
                      template(v-for="(item, index) in length")
                        +b.g-cell.g-cols.pass-length(v-if="5 <= index")
                          label(
                            :for="item.value"
                          )
                            input(
                              class="length-radio"
                              type="radio" 
                              :id="item.value"
                              :value="item.value"
                              v-model="gLength")
                            +b.length-label {{ item.value }}          
          +e.config
            +e.config-title  
              +b.P.ds-caption.--bold.--size_md.--color_dark-blue {{ $t("Генерировать из символов") }}
            +e.user-characters-config
              +b.g-row.--justify_center-till-xl.--appearance_spaced.--appearance_column-till-xl.--space_xl.--align_start-till-xl.--align_center
                template(v-for="type in characters") 
                  +b.g-cell.g-cols--12(v-if="type.input")
                    d-control-input(
                      class="input-main-gray text-aling-start-xl"
                      name='key',
                      input-label=''
                      v-model='type.value'
                      @input="setUserCharacters(type.value)"
                    )
                  +b.g-cell.g-cols--12(v-if="type.input")
                    +b.P.ds-caption.--bold.--size_md.--color_dark-blue {{ $t("Правила генерации") }}  
                  +b.g-cell.g-cols--narrow.--6-xl(v-else)
                    +b-context('pass-gen-block')
                      +e.config-checkboxes(
                        :class="userCharacters ? 'pass-gen-block__config-checkboxes--disabled' : ''"
                      )
                        +e.LABEL.label(:for="type.name")
                          +e.INPUT.checkbox(
                            type="checkbox"
                            :id="type.name"
                            v-model="type.checked"
                            :disabled="!!userCharacters"
                          )
                          +e.DIV.checkbox-icon
                        +b.SPAN.ds-caption.--medium.--size_sm.--color_dark-blue {{ type.title }} #[span(v-if='type.additional') (!@#$%^ {{ $t('и др.') }})]
                +b.g-cell.g-cols--narrow
                  +b-context('pass-gen-block')
                    +e.config-checkboxes(
                      :class="userCharacters ? 'pass-gen-block__config-checkboxes--disabled' : ''"
                    )
                      +e.LABEL.label(for="recurring")
                        +e.INPUT.checkbox(
                          type="checkbox"
                          id="recurring"
                          v-model="recurringDisable"
                          :disabled="userCharacters ? true : false"
                        )
                        +e.DIV.checkbox-icon
                      +b.SPAN.ds-caption.--medium.--size_sm.--color_dark-blue {{ $t("Исключать похожие символы") }}
                +b.g-cell.g-cols--12
                  +b.P.ds-caption.--bold.--size_md.--color_dark-blue {{ $t("Рекомендовано") }}            
                +b.g-cell.g-cols--12.--7-xl
                  +b.output-block.--password-style.relative
                    +b.INPUT.output.--style_main.relative(
                      :value="password[0]"
                      readonly='true'
                    )
                    +e.img.--pass-gen-style
                      copy-to-clip-board(
                        :text="password[0]"
                        :centered="true"
                      )
                        +e.IMG(
                          src='/static/app/copy-icon.svg'
                          alt='copy icon'
                        )
                +b.g-cell.g-cols--12.--5-xl
                  +b.hq-btn.--auth-style-green.--centered.--br-radius_main.--centered.--full_width(
                    type='submit'
                    @click.prevent="onGenerate"
                  )
                    //- :disabled='isLoad'
                    +e.P.text.ds-caption.--color_white.--bold {{ $t("Сгенерировать") }}
      +b.g-cell.g-cols--12.--5-xl
        +b.g-row.--justify_center-till-xl.--align_center
          +b.g-cell.g-cols--12.--6-xl          
            +b.UL.pass-list.--space-left-xl
              +e.LI.element(
                v-for="(pass, index) in password"
                :key="`pass_${index}`"
                )
                copy-to-clip-board(
                  :text="pass"
                  :centered="true"
                )
                  +b.P.ds-caption.--medium.--size_3xs.--size_2xs-sm.--color_second-gray {{ pass }}    
          +b.g-cell.g-cols.--6-xl
            +b.pass-gen-images
              +e.IMG.img.disabled-xl(
                src='/static/app/pass-gen-img1.svg'
                alt='password generation image'
              )
              +e.IMG.img.disabled-till-xl(
                src='/static/app/pass-gen-img1-large.svg'
                alt='password generation image'
              )
              
</template>

<script >
import useGlobal from '../../composables/useGlobal'
export default {
  data() {
    const { $t } = useGlobal()
    return {
      recurringDisable: false,
      length: [{ value: 4 }, { value: 5 }, { value: 6 }, { value: 8 }, { value: 10 }, { value: 12 }, { value: 16 }, { value: 20 }, { value: 24 }, { value: 32 }],
      userCharacters: "",
      charactersBase: "",
      excluded: 'Iij!1l6boO0Q8B',
      characters: [
        {
          input: true,  
          name: "User Characters",
          value: "",
          checked: false,
        },
        {
          title: $t('A-Z (прописные буквы)'),
          name: "Uppercase",
          value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
          checked: true,
        },
        {
          title: $t('a-z (строчные буквы)'),
          name: "Lowercase",
          value: "abcdefghijklmnopqrstuvwxyz",
          checked: false,
        },
        {
          title: $t('Цифры (0-9)'),
          name: "Numbers",
          value: "0123456789",
          checked: false,
        },
        {
          title: $t('Спецсимволы'),
          additional: $t('и др.'),
          name: "Special Characters",
          value: "_-+=)(*&^%$#@!`~",
          checked: false,
        },
      ],
      password:  [],
      gLength: 4,
      }
  },
    methods: {
    excludeRecurring(target) {
      let obj = target
      for (var j = 0; j < this.excluded.length; j++) {
        obj = obj.replaceAll(this.excluded[j], '')
      }
      return obj
      // const obj = target.split('').reduce((o, v, i) => { o[v] = v; return o; }, {})
      // const arrUnique = Object.keys(obj)
      // const output = arrUnique.join('')
      // return output
    },
    setUserCharacters(value) {
      this.userCharacters = value
      this.recurringDisable = false
      this.characters.forEach(type => {
        if(type.input && value) {
          type.checked = true
        } else {
          type.checked = false
        }
      });
    },
    generatePass(length) {
      let result = "";
      let charactersVal = "";
      let check = this.characters.some(function (j) {
        if(j.checked) {
          return true
        } else {
          return false
        } 
      })
      for (var j = 0; j < this.characters.length; j++) {
        if (this.characters[j].checked) {
          charactersVal += this.characters[j].value;
        }
        if(!check) {
          charactersVal += this.characters[j].value; 
        }
      }
      for ( var i = 0; i < length; i++ ) {
        result += charactersVal.charAt(Math.floor(Math.random() * charactersVal.length));
        
      }
      this.charactersBase = charactersVal
      return result
    }, 
    checkPassLength(pass) {
      if(pass.length < this.gLength) {
        let diff = this.gLength - pass.length 
        pass += this.generatePass(diff)
        pass = this.checkPassLength(this.excludeRecurring(pass))
        return pass
        } 
      // no else after return
      return pass  
      
    },
    onGenerate() {
        this.password = []
        for ( let i = 0; i <= 17; i++) {
          
          let pass = this.generatePass(this.gLength)
          if (this.recurringDisable) {
            if(this.charactersBase.length < this.gLength) {
              pass = this.excludeRecurring(pass)
              this.password.push(pass)
            } else {
              pass = this.excludeRecurring(pass)
              this.password.push(this.checkPassLength(pass))
            }
          } else {
            this.password.push(pass)
          }
        }
        return
      },
    },
}

</script>
