import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-control-input d-control-input--appearance_bordered" }
const _hoisted_2 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({ class: "d-control-input__element" }, _ctx.$attrs, {
      autocomplete: "off",
      type: $setup.inputType
    }), null, 16, _hoisted_2),
    ($setup.isPasswordField)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "change-type",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleType()))
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.inputType === $setup.p ? 'IEye' : 'IEyeOff')))
        ]))
      : _createCommentVNode("", true)
  ]))
}