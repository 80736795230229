const event = 'ConfirmEmail'

export const sendConfirmEmail = (email: string, subtype: string): void => {
  if (!window.isAnalyticEnabled) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event,
    email,
    subtype,
  })
}
