import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hq-modal hq-modal--scan" }
const _hoisted_2 = { class: "hq-modal__qr-block" }
const _hoisted_3 = { class: "ds-panel ds-panel--space_3xl" }
const _hoisted_4 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_5 = { class: "hq-btn__text ds-caption ds-caption--size_2md ds-caption--color_white ds-caption--bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qr_code = _resolveComponent("qr-code")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_qr_code, {
        "qr-code": _ctx.$attrs.initial
      }, null, 8, ["qr-code"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "hq-btn hq-btn--simple-blue hq-btn--centered hq-btn--br-radius_main hq-btn--full_width",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$attrs.close()))
        }, [
          _createElementVNode("p", _hoisted_5, _toDisplayString($setup.$t('Ок')), 1)
        ])
      ])
    ])
  ]))
}