<template lang="pug">
include /mixins.pug
+b.pagination
  +e.wrapper(v-if='page && range.max > 1')
    +e.A.item.--center.--left(
      href='#',
      aria-label="Previous"
      @click.prevent='previous()'
      :class='{"is-disabled": !page.hasPrevious()}'
    )
      +e.arrow-left.IMG(
        src='/static/app/arrow-left.svg'
        alt="arrow left icon"
      )

    +e('ul').list
      template(v-if='page.hasPrevious()')
        +e.LI.list-item.--passive.--bordered
          +e.A.link(href='#', @click.prevent='select(range.min)')
            +e.SPAN.text {{ range.min }}
      +e.LI.list-item.--passive.--gag(v-if='gaps.left')
        +e.SPAN.text ...
      +e.LI.list-item.--passive.--bordered(v-for='page in fills.left')
        +e.A.link(href='#', @click.prevent='select(page)')
          +e.SPAN.text {{ page }}
      +e.LI.list-item.--active.--bordered
        +e.link
          +e.SPAN.text(v-text='page.getPageNumber()')
      +e.LI.list-item.--passive.--bordered(v-for='page in fills.right')
        +e.A.link(href='#', @click.prevent='select(page)')
          +e.SPAN.text {{ page }}
      +e.LI.list-item.--passive.--gag(v-if='gaps.right')
        +e.SPAN.text ...
      template(v-if='page.hasNext()')
        +e.LI.list-item.--passive.--bordered
          +e.A.link(href='#', @click.prevent='select(range.max)')
            +e.SPAN.text {{ range.max }}

    +e.A.item.--center.--right(
      href='#',
      aria-label="Next"
      @click.prevent='next()'
      :class='{"is-disabled": !page.hasNext()}'
    )
      +e.arrow-right.IMG(
            src='/static/app/arrow-right.svg'
            alt="arrow right icon"
          )

</template>

<script>
/* eslint-disable */
import { LimitOffsetPagination } from './Pagination'

export default {
  template: '<div></div>',
  name: 'UiLimitPagination',
  props: {
    pagination: {},
    extra: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      page: {},
      range: {
        min: 0,
        max: Infinity,
      },
      gaps: {
        left: false,
        right: false,
      },
      fills: {
        left: [],
        right: [],
      },
      one: 1,
      zero: 0,
    }
  },

  created() {
    if (this.page) this.recalcRange()
  },

  computed: {
    pageObj() {
      const to = this.page.limit + this.page.offset
      const from = this.page.offset + this.one

      return { to, from }
    },
  },
  mounted () {
  },
  watch: {
    pagination: {
      deep: true,
      immediate: true,
      handler() {
        this.page = new LimitOffsetPagination(this.pagination)
      },
    },
    page() { this.recalcRange() },
  },

  methods: {
    recalcRange() {
      const [min, max] = this.page.getPageRange()
      const current = this.page.getPageNumber()
      const [start, end] = this.page.getPageRange(this.extra, current)
      // Some javascript magic going on here:
      // Filling up array with page numbers.
      const range = Array
        .apply(null, { length: end - start + this.one })
        .map((x, i) => i + start)
      const currentIndex = range.indexOf(current)
      if (currentIndex !== -this.one) {
        this.fills.left = range.slice(this.zero, currentIndex)
        const minIndex = this.fills.left.indexOf(min)
        if (minIndex >= this.zero) {
          this.fills.left.splice(minIndex, this.one)
        }
        this.fills.right = range.slice(currentIndex + this.one)
        const maxIndex = this.fills.right.indexOf(max)
        if (maxIndex >= this.zero) {
          this.fills.right.splice(maxIndex, this.one)
        }
      } else {
        this.fills.left = []
        this.fills.right = []
      }

      this.gaps.left = (
        this.fills.left.length &&
        this.fills.left[0] !== min + this.one
      )
      this.gaps.right = (
        this.fills.right.length &&
        this.fills.right[this.fills.right.length - this.one] !== max - this.one
      )
      this.range.min = min
      this.range.max = max
    },

    select(number) {
      this.$emit('input', this.page.getParameters(number))
    },

    next() {
      this.$emit('input', this.page.next())
    },

    previous() {
      this.$emit('input', this.page.previous())
    },
  },
}

</script>
