<template lang="pug">
include /mixins
+b.payments
  +b.auth-form.--bordered.--full_width-xl.--space-sm.relative
    loader(
      v-if='load'
    )
    +b.g-row.--justify_center.--appearance_spaced.--space_md
      +b.g-cell.g-cols--12
        +b.g-row.--justify_center.--align_center.--justify_between-xl.--appearance_spaced.--space_sm.--space_xl-xl
          +b.g-cell.g-cols--12.--3-xl(
            v-if='sub'
          )
            +b-context("subscription-card")  
              +e.naming
                +b.P.ds-caption.--medium.--size_2md-xl.--color_dark-blue.--appearance_center {{ $t("Название") }}{{":"}}  
              +e.title.--offset-bottom-md
                +b.P.ds-caption.--size_3exxl.--size_4xl-xl.--bold.--color_dark-blue.--appearance_center {{ sub.title }} 
              +e.period
                +b.P.ds-caption.--thin.--color_blue.--appearance_center {{ $t("Срок подписки") }} {{ sub.label }}
              +e.price-title.--offset-top-md
                +b.P.ds-caption.--medium.--size_2md-xl.--color_dark-blue.--appearance_center {{ $t("Стоимость подписки") }}{{":"}} 
              +e.price.--new.full_width
                +b.P.ds-caption.--size_5xl.--size_special-5xl-xl.--medium.--color_dark-blue.--appearance_center {{ "$" }} {{ sub.price_sale * sub.months_count }}
              +e.price.--old.full_width
                +b.P.ds-caption.--size_2md.--size_2xl-xl.--medium.--color_middle-gray.--appearance_center(:class='sub.price_default == sub.price_sale ? "hidden-element" : ""') {{ "$" }} {{ sub.price_default * sub.months_count }}
              +e.change-link(
                v-if="!sub_key"
                @click="backToSubs()"
              )
                +b.SPAN.ds-caption.--size_3xs.--size_2md-xl.--bold.--color_middle-gray.--appearance_center  {{ $t("Изменить пакет") }}
                +e.IMG.edit-icon(
                  src='/static/app/edit-icon.svg'
                  alt='edit icon'
                )  
          +b.g-cell.g-cols--12.--auto-xl
            +b.payments-cards-block
              +e.title.disabled-xl
                +b.P.ds-caption.--size_2md.--size_4xl-xl.--bold.--color_dark-blue.--appearance_center {{ $t("Выберите удобный для вас способ оплаты") }} 
              +b.g-row.--justify_center.--justify_end-xl.--appearance_spaced.--space_sm.--space_xl-xl
                +b.g-cell.g-cols--narrow(
                  v-for="(item, index) in icons"
                )
                  +b.payment-card.A(
                    :key="item.name"
                    @click.prevent="createPayment(item)"
                  )
                    +b.payment-card-wrapper(
                      :id='item.gtagKey'
                    )
                    +e.icon
                      +e.IMG.img(
                        width="137"
                        :height="item.name == 'coinpayments' ? 29 : 54"
                        :src='`/static/app/${item.name}.svg`'
                        :alt='item.name'
                      )

</template>

<script>
import { 
  paymentsList,
  coinPaymentCreate,
  // googlePaymentCreate,
  paypalPaymentCreate,
  stripePaymentCreate
} from '@/services/order.service'
import { storage } from '@/utils/session'
import {
  HQ_VPN_CURRENT_PAYMENT_ID_NAME,
  HQ_VPN_CURRENT_PAYMENT_URL_NAME,
  HQ_USER_SAVED_NAME,
} from '@/const'

import {
  setCookie,
} from '@/utils/cookies'

import executeWithRefresh from '@/composables/useRefresher'
import { STATE } from '@/utils/base64'

const urlGenerator = (type, params) => `${window.location.origin}/payment/${type}/?${STATE.stringify(params)}`

export default {
  props:['sub', 'order', 'sub_key'],
  subscription: {
    step: {
      type: Object,
    },
  },
  data() {
    return {
      paymentServices: {
        stripe: stripePaymentCreate,
        coinpayments: coinPaymentCreate,
        paypal: paypalPaymentCreate
      },
      icons: [
        {
          name: "coinpayments",
          gtagKey: 'Crypto',
        }, {
          name: "paypal",
          gtagKey: 'PayPal',
        }, {
          name: "stripe",
          gtagKey: 'Stripe',
        }
      ],
      payList: "",
      load: false,
    }
  },
  methods: {
    backToSubs() {
      if(window.location.search) {
        window.location.search = ''
      } else {
        this.$emit('back')
      }
    },
    async getPaymentsList () {
      try {
        const res = await paymentsList.execute()
        this.payList = res.data.items
      } catch(e) {
        return Promise.reject(e)
      }
    },
    async createPayment(service) {
      const params = {
        order_id: this.order,
        subscription_id: this.sub.id,
        key: this.sub_key,
        subscription_key: this.sub.label,
        service: service.gtagKey,
      }

      const success_url = urlGenerator('success', params )
      const cancel_url = urlGenerator('cancel', params)
    
      const paymentData = {
        stripe: {
          order: this.order,
          success_url,
          cancel_url,
        },
        coinpayments: {
          order: this.order,
          success_url,
        },
        paypal: {
          order: this.order,
          success_url,
          cancel_url,
        }, 
      }
      try {
        this.load = true
        const { data: { payment: { id }, result: { redirect_url } } } = (await executeWithRefresh(this.paymentServices[service.name], null, {}, paymentData[service.name]))
        storage.setItem(HQ_VPN_CURRENT_PAYMENT_ID_NAME, id)
        storage.setItem(HQ_VPN_CURRENT_PAYMENT_URL_NAME, redirect_url)
        this.load = false
        setCookie(HQ_USER_SAVED_NAME, '')
        window.location.href = redirect_url
      } catch(e) {
        this.load = false
        return Promise.reject(e)
      }
   },
  },
  mounted() {
    this.getPaymentsList()
  },
 
}

</script>
