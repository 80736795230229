/* eslint-disable */
const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  console.log('-------', errors );
  return Object.keys(errors).reduce((acc, x) => {
    acc[x] = errors[x].map(messageGetter)
    return acc
  }, {})
}

export function useSubmit(send, control) {
  function catchFormErrors(promise, controller) {
    return promise.catch(e => {
      const clientError = 400
      const serverError = 500
      if (!e.status || clientError > e.status || serverError <= e.status) {
        throw e
      }
  
      if (!updateValidator) {
        return e
      }
  
      return e.json().then(body => {
        const errors = {}
  
        body.errors.forEach(error => {
          if ('request' === error.domain && error.state) {
            Object.assign(errors, error.state)
          }
        })
  
        updateValidator(errors, controller)
  
        return e
      })
    })
  }
  
  function updateValidator(errors, controller) {
    controller.setFieldError('nonFieldErrors', '')
    const parsed = defaultValidatorErrorsParser(errors)
    Object.keys(parsed).forEach(key => {
      controller.setFieldError(key, parsed[key][0])
    })
  }
  
  function submit(send, control) {
    return catchFormErrors(send, control)
  }

  submit(send, control)

}
