<template lang="pug">
include /mixins
+b.steps(
  :class="`steps--${stepsClass[step - 1]}`"
)
  +e.number 1
  +e.compound
  +e.number 2
  +e.compound(v-if="!shortened")
  +e.number(v-if="!shortened") 3
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    shortened: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
     stepsClass: ["first", "second", "third"]
    }
  },
 
}

</script>
