import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "auth-form auth-form--test-access" }
const _hoisted_2 = {
  key: 0,
  class: "ds-caption ds-caption--size_2md ds-caption--size_5xl-xl ds-caption--bold ds-caption--color_dark-blue ds-caption--appearance_center"
}
const _hoisted_3 = {
  key: 1,
  class: "ds-caption ds-caption--size_2md ds-caption--size_5xl-xl ds-caption--bold ds-caption--color_dark-blue"
}
const _hoisted_4 = {
  key: 2,
  class: "ds-caption ds-caption--size_2md ds-caption--size_5xl-xl ds-caption--bold ds-caption--color_dark-blue ds-caption--appearance_center"
}
const _hoisted_5 = { class: "auth-form__wrapper" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_lg g-row--space_2xl-xl" }
const _hoisted_7 = { class: "error-label" }
const _hoisted_8 = {
  key: 0,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_9 = { class: "test-acces-input-label" }
const _hoisted_10 = { class: "ds-caption ds-caption--size_2md-xl ds-caption--bold ds-caption--color_dark-blue" }
const _hoisted_11 = { class: "g-cell g-cols g-cols--12 g-cols--6-xl" }
const _hoisted_12 = { class: "ds-panel ds-panel--space_md ds-panel--space_none-xl" }
const _hoisted_13 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_14 = { class: "hq-btn__text ds-caption ds-caption--color_white ds-caption--bold ds-caption--size_2md" }
const _hoisted_15 = {
  key: 1,
  class: "hq-btn hq-btn--blue hq-btn--centered hq-btn--auth-style hq-btn--full_width hq-btn--full_height-xl link link--shadowed",
  href: "/"
}
const _hoisted_16 = { class: "hq-btn__text ds-caption ds-caption--color_white ds-caption--bold ds-caption--size_2md" }
const _hoisted_17 = {
  key: 2,
  class: "hq-btn hq-btn--blue hq-btn--centered hq-btn--auth-style hq-btn--full_width hq-btn--full_height-xl hq-btn--shadowed",
  type: "submit"
}
const _hoisted_18 = { class: "hq-btn__text ds-caption ds-caption--color_white ds-caption--bold ds-caption--size_2md" }
const _hoisted_19 = { class: "g-cell g-cols g-cols--12 g-cols--6-xl" }
const _hoisted_20 = ["href"]
const _hoisted_21 = { class: "hq-btn__text ds-caption ds-caption--color_white ds-caption--bold ds-caption--size_2md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_vee_form = _resolveComponent("vee-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["auth-form__title", $setup.mailSended && !$setup.mailError ? 'auth-form__title--offset-bottom-xl' : ''])
    }, [
      ($setup.mailSended && !$setup.mailError)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString($setup.$t("На вашу почту отправлена ссылка для получения временного доступа")), 1))
        : (!$setup.mailSended && !$setup.mailError)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString($setup.$t("Тестовый доступ на 1 день")), 1))
          : ($setup.mailError)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString($setup.alreadyUsed || $setup.$t("Что-то пошло не так, говорят нет такой почты...")), 1))
            : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_vee_form, {
        class: "hq-form",
        onSubmit: $setup.request
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_7, _toDisplayString(message), 1)
              ]),
              _: 1
            }),
            (!$setup.mailSended && !$setup.mailError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString($setup.$t("E-mail")), 1)
                  ]),
                  _createVNode(_component_d_control_input, {
                    class: "input-main-gray",
                    rules: "required|email",
                    name: "owner",
                    "input-label": "",
                    modelValue: $setup.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event))
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  ($setup.mailError)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "hq-btn hq-btn--blue hq-btn--centered hq-btn--auth-style hq-btn--full_width hq-btn--full_height-xl hq-btn--shadowed",
                        type: "submit",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.mailError = false))
                      }, [
                        _createElementVNode("p", _hoisted_14, _toDisplayString($setup.$t("Ввести другую")), 1)
                      ]))
                    : ($setup.mailSended && !$setup.mailError)
                      ? (_openBlock(), _createElementBlock("a", _hoisted_15, [
                          _createElementVNode("p", _hoisted_16, _toDisplayString($setup.$t("На главную")), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("button", _hoisted_17, [
                          _createElementVNode("p", _hoisted_18, _toDisplayString($setup.$t("Временный доступ")), 1)
                        ]))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("a", {
                class: "hq-btn hq-btn--green hq-btn--centered hq-btn--br-radius_main hq-btn--full_width hq-btn--full_height-xl hq-btn--shadowed link",
                href: $setup.props.subscriptionLink
              }, [
                _createElementVNode("span", _hoisted_21, _toDisplayString($setup.$t('Купить подписку')), 1)
              ], 8, _hoisted_20)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}