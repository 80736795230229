import { App } from 'vue'
import SidebarTrigger from './SidebarTrigger.vue'
// import Controller from './Controls/ControlDescriptor.vue'
// import InputLabel from './Controls/Label.vue'
// import InputTextArea from './Controls/TextArea.vue'
import UiModalsContainer from './Modals/Container.vue'
import UiMessageModal from './Modals/UiMessageModal.vue'
import UiConfirmModal from './Modals/UiConfirmModal.vue'
import ModalTrigger from './ModalTrigger.vue'
import GMap from './GMap.vue'

import SimpleInput from './Controls/SimpleInput.vue'
import Multiselect from './Controls/Multiselect.vue'
import LangSelector from './LangSelector.vue'
import PasswordGen from './PasswordGen.vue'
import StepDetector from './StepDetector.vue'
import UiAccordion from './Parts/UiAccordion.vue'
import UiSlider from './UiSlider.vue'
import MainSlider from './MainSlider.vue'
import QrCode from './Qr-code.vue'
import UiClickOutside from './Parts/UiClickOutside.vue'
import CopyToClipBoard from './CopyToClipBoard.vue'
import Loader from './loader.vue'

import AgileSlider from './Agile/Agile.vue'
import UiLimitPagination from './Pagination/UiLimitPagination.vue'


const register = (app: App<Element>): void => {
  app
    .component('SidebarTrigger', SidebarTrigger)
    .component('UiLimitPagination', UiLimitPagination)
    
    // .component('DController', Controller)
    // .component('InputLabel', InputLabel)
    // .component('InputTextArea', InputTextArea)
    .component('ModalTrigger', ModalTrigger)
    .component('UiModalsContainer', UiModalsContainer)
    .component('UiMessageModal', UiMessageModal)
    .component('UiConfirmModal', UiConfirmModal)
    .component('CopyToClipBoard', CopyToClipBoard)

    .component('SimpleInput', SimpleInput)
    .component('Multiselect', Multiselect)
    .component('LangSelector', LangSelector)
    
    .component('UiAccordion', UiAccordion)
    .component('UiClickOutside', UiClickOutside)
    .component('UiSlider', UiSlider)
    .component('StepDetector', StepDetector)
    .component('PasswordGen', PasswordGen)
    .component('AgileSlider', AgileSlider)
    .component('MainSlider', MainSlider)
    .component('QrCode', QrCode)
    .component('Loader', Loader)
    .component('GMap', GMap)

}

export default {
  register,
}
