
import { $vfm } from 'vue-final-modal'

import UiModalsContainer from '@/components/UiElements/Modals/Container.vue'
import TrialAccessModal from '@/components/modals/TrialAccessModal.vue'

const settings = {
  component: TrialAccessModal,
  classes: {
    type: Array,
  },
  initial: {},
  clickToClose: {
    default: true,
  },
}

const modalOpener = () => $vfm.show({
  component: UiModalsContainer, 
}, {
  ...settings,
})

export function openDemoModal() {
    modalOpener()
  }
