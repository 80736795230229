Element.prototype.documentOffsetTop = function () {
  return this.offsetTop + ( this.offsetParent ? this.offsetParent.documentOffsetTop() : 0 )
}

export const scroller = async () => {
  const { hash } = window.location
  if (!hash) return
  const [, id] = hash.split('#')
  setTimeout(async () => {
    const offset = 90
    const top = document.getElementById(id).documentOffsetTop() - offset
    window.scrollTo({ top, behavior: 'smooth' })
  }, 500)
}