import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DControlLabel = _resolveComponent("DControlLabel")!
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_VeeError = _resolveComponent("VeeError")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-descriptor", { error: $props.errors[$props.name] || !$setup.isValid && 'TelWidget' === $props.component, success: $props.isSuccess }])
  }, [
    _createVNode(_component_DControlLabel, _mergeProps({
      class: $props.staticLabel ? 'd-control-input__label--static': 'd-control-input__label--bound',
      "input-label": $props.staticLabel ? $props.staticLabel: $props.inputLabel,
      "is-required": $setup.isRequired
    }, _ctx.$attrs, { value: $setup.inputValue }), null, 16, ["class", "input-label", "is-required", "value"]),
    _createVNode(_component_VeeField, _mergeProps({
      as: $props.component,
      name: $props.name,
      options: $props.options,
      type: $props.type,
      labelBy: $props.labelBy,
      rules: $props.rules,
      "set-value": $props.setValue,
      slots: _ctx.$slots
    }, _ctx.$attrs, {
      "onUpdate:modelValue": $setup.updateValue,
      "onUpdate:isValid": _cache[0] || (_cache[0] = ($event: any) => ($setup.isValid = $event))
    }), null, 16, ["as", "name", "options", "type", "labelBy", "rules", "set-value", "slots"]),
    _createVNode(_component_VeeError, { name: $props.name }, {
      default: _withCtx(({ message }) => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(message), 1)
      ]),
      _: 1
    }, 8, ["name"])
  ], 2))
}