import {
  refreshToken,
} from '@/services/auth.service'
import useAuthToken from '@/composables/useAuthToken'
import { storage } from '@/utils/session'
import {
  HQ_VPN_REFRESH_TOKEN_STORAGE_NAME,
  HQ_VPN_ACCESS_TOKEN_STORAGE_NAME,
  HQ_VPN_IS_AUTH_STORAGE_NAME,
} from '@/const'

import useLogout from '@/composables/useLogout'

const UNAUTHORIZED = 401

export default async function executeWithRefresh(resource, token?: string|null, params?:Record<string, unknown>, payload?:Record<string, unknown>, refreshKey?: string|null) {
  const resourceWithMiddleware = (await useAuthToken(resource, token, refreshKey))
  try {
    const result = (await resourceWithMiddleware.execute(params, payload || null))
    return result
  } catch (e: any) {
    if (e.status === UNAUTHORIZED) {
      storage.setItem(HQ_VPN_IS_AUTH_STORAGE_NAME, false)
      try {
        const { access, refresh } = (await refreshToken.execute({}, { refresh: storage.getItem(HQ_VPN_REFRESH_TOKEN_STORAGE_NAME) }))
        // storage.setItem(HQ_VPN_IS_AUTH_STORAGE_NAME, true)
        return executeWithRefresh(resource, access, params, payload, refresh)
      } catch (error) {
        await useLogout()
        storage.setItem(HQ_VPN_IS_AUTH_STORAGE_NAME, false)
        // если в лк - редиректить на главную
        // туть
        // window.location.href = '/auth/login/'
        return executeWithRefresh(resource, null, params, payload)
      }
    }
    return Promise.reject(e)

  }

}