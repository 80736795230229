import {
  createSender,
  createReceiver,
} from '@/resource/resource'

import {
  prefixAPI,
  API_EXTERNAL_URL,
} from '@/resource/api'

const MODEL = 'orders'
const KEY_MODEL = 'keys'

// if external Alex API add third argument API_EXTERNAL_URL
// else leave empty
// const AUTHENTICATE_LINK = prefixAPI('URL, MODEL, BASE) = BASE + MODEL + URL

const PAYMENTS_LIST_LINK = prefixAPI('/payments/ways/list/', MODEL, API_EXTERNAL_URL)
const SUBSCRIPTION_CREATE_LINK = prefixAPI('/create/subscription/', MODEL, API_EXTERNAL_URL)
const USER_ORDERS_LIST_LINK = prefixAPI('/own/list/', MODEL, API_EXTERNAL_URL)
const USER_KEYS_LIST_LINK = prefixAPI('/own/list/{?limit,offset}', KEY_MODEL, API_EXTERNAL_URL)
const USER_KEY_SWAP_LINK = prefixAPI('/own/{key}/swap/', KEY_MODEL, API_EXTERNAL_URL)
const ORDER_RETRIEVE_LINK = prefixAPI('/{id}/retrieve/', MODEL, API_EXTERNAL_URL)
const ORDER_PAYMENT_RETRIEVE_LINK = prefixAPI('/payments/{id}/retrieve/', MODEL, API_EXTERNAL_URL)

const COIN_PAYMENT_CREATE_LINK = prefixAPI('/payments/pay-order/coinpayments/', MODEL, API_EXTERNAL_URL)
const GOOGLE_PAYMENT_CREATE_LINK = prefixAPI('/payments/pay-order/google_play/', MODEL, API_EXTERNAL_URL)
const PAYPAL_PAYMENT_CREATE_LINK = prefixAPI('/payments/pay-order/paypal/', MODEL, API_EXTERNAL_URL)
const STRIPE_PAYMENT_CREATE_LINK = prefixAPI('/payments/pay-order/stripe_session/', MODEL, API_EXTERNAL_URL)

export const paymentsList = createReceiver(PAYMENTS_LIST_LINK)
export const createSubscription = createSender(SUBSCRIPTION_CREATE_LINK)
export const userOrdersListResource = createReceiver(USER_ORDERS_LIST_LINK)
export const userKeysListResource = createReceiver(USER_KEYS_LIST_LINK)
export const userKeySwap = createSender(USER_KEY_SWAP_LINK)

// get order info on Success or Cancel page!
export const orderRetrieveResource = createReceiver(ORDER_RETRIEVE_LINK)
export const orderPaymentResource = createReceiver(ORDER_PAYMENT_RETRIEVE_LINK)

export const coinPaymentCreate = createSender(COIN_PAYMENT_CREATE_LINK)
export const googlePaymentCreate = createSender(GOOGLE_PAYMENT_CREATE_LINK)
export const paypalPaymentCreate = createSender(PAYPAL_PAYMENT_CREATE_LINK)
export const stripePaymentCreate = createSender(STRIPE_PAYMENT_CREATE_LINK)

