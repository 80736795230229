import {
  emailErrMessage,
  requiredErrMessage,
  confirmedErrMessage,
  minErrMessage,
} from '../../main'

/* eslint-disable */
export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined;
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0;
}

export function getSingleParam<TParam = unknown>(params: [TParam] | Record<string, TParam>, paramName: string) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

const required = (value: any) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
    return requiredErrMessage
  }
  return true
}

const email = (value: any) => {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!EMAILREG.test(value)) {
    return emailErrMessage
  }
  return true
}

const confirmed = (value: any, [target]: string) => {
  console.log("🚀 ~ file: rules.ts:53 ~ confirmed ~ target:", target)
  console.log("🚀 ~ file: rules.ts:53 ~ confirmed ~ value:", value)
  
  if (value === target) {
    return true
  }
  return confirmedErrMessage
}

const min = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (String(value).length >= Number(length)) {
    return String(value).length >= Number(length)
  } else {
    return `${minErrMessage}:${length}`
  }
}

export {
  required,
  email,
  confirmed,
  min,
}
