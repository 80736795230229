const event = 'ChooseSubscription'

export const sendChooseSubscription = (subtype: string): Promise<boolean> => {
  if (!window.isAnalyticEnabled) return
  return new Promise((resolve, reject) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event,
      subtype,
    })
    resolve(true)
  })
}
