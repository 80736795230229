import { App } from 'vue'

import i18n from './i18n'
import Validation from './validation/plugin'
import StarRating from './vue-star-rating'
import StarRating3 from './vue3-star-rating'
import VueFinalModal from './vue-final-modal'
import VueGoogleMaps from './vue-google-maps'
import farewellDirectivePlugin from './vue-farewell'
// import VueSlider from './vue-slider-component'
// import Tippy from './vue-tippy'
// import MazPhoneNumberInput from './vue-maz-phone-number-input'
// import VueSocialSharing from './vue-social-sharing'
// import VueClipboard from './vue-clipboard'
// import VueEasyLightbox from './vue-easy-lightbox'



export default {
  install(app: App<Element>) {
    app
      .use(i18n)
      .use(StarRating)
      .use(StarRating3)
      .use(Validation)
      .use(VueFinalModal)
      .use(VueGoogleMaps)
      .use(farewellDirectivePlugin)
      // .use(VueSlider)
      // .use(Tippy)
      // .use(MazPhoneNumberInput)
      // .use(VueSocialSharing)
      // .use(VueClipboard)
      // .use(VueEasyLightbox)
  } 
}
