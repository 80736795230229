import { App } from 'vue'
import Subscriptions from './Subscriptions.vue'
import UserCabinet from './UserCabinet.vue'
import StepOne from './StepOne.vue'
import StepTwo from './StepTwo.vue'
import CabinetView from './CabinetView.vue'
import PaymentSelector from './PaymentSelector.vue'

const register = (app: App<Element>): void => {
  app
  .component('Subscriptions', Subscriptions)
  .component('UserCabinet', UserCabinet)
  .component('StepOne', StepOne)
  .component('StepTwo', StepTwo)
  .component('CabinetView', CabinetView)
  .component('PaymentSelector', PaymentSelector)
}

export default {
  register,
}
