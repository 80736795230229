<template lang="pug">
include /mixins.pug
AgileSlider(
  ref="carousel"
  :options="settings"
  @after-change='emit("slide:change", $event)'
)
  slot

  template(#prevButton)
    component(
      :is='arrowLeft'
    )

  template(#nextButton)
    component(
      :is='arrowRight'
    )

</template>

<script setup>
import { defineProps, defineEmits, ref,defineExpose } from 'vue'

const props = defineProps({
  settings: Object,
  arrowLeft: String,
  arrowRight: String,
})

const emit = defineEmits([
  'slide:change'
])

const carousel = ref()

const goTo = i => {
  carousel.value.goTo(i)
}

defineExpose({
  goTo
})

</script>
