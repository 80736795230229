import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "qr-content-wrap"
}
const _hoisted_2 = { class: "qr-content-wrap__content" }
const _hoisted_3 = {
  class: "canvas-code",
  id: "canvas",
  ref: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.code.qrCode)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("canvas", _hoisted_3, null, 512)
        ])
      ]))
    : _createCommentVNode("", true)
}