<template lang="pug">
include /mixins
+b.DIV.copy-wrapper(
  :class="text ? '' : 'copy-wrapper--disabled'"
  @click.prevent="copyToClipboard(text)"
  ) 
  +e.message(v-if="showMessage" :class="centered ? 'copy-wrapper__message--centered' : ''")
    +b.P.ds-caption.--size_3xs.--color_blue.--appearance_center(v-if="text") {{ $t('Скопировано') }}
    +b.P.ds-caption.--size_3xs.--color_blue.--appearance_center(v-else) {{ $t('Нечего копировать') }}
  slot

</template>

<script>

import useGlobal from '../../composables/useGlobal'


export default {
  name: 'CopiToClip',
  props: ['text', 'centered'],
  data() {
    const { $t } = useGlobal()
    return {
      showMessage: false
    };
  },
  methods: {
    copyToClipboard(data) {
      navigator.clipboard.writeText(data)
      this.showMessage = true
      setTimeout(() => {
        this.showMessage = false
      }, 1500);
    },
  },
};
</script>
