<template lang="pug">
include /mixins
+b.g-row.--apperance_center.--space_none
  +b.g-cell.g-cols--12.--auto-xl.--oreder_1-xl
    slot(
      :goTo='(i) => goTo(i, $refs.slider)'
      name='text'
      :currentSlide='currentSlide'
    )
  +b.g-cell.g-cols--12.--narrow-xl(dir="ltr")
    +b.slider-block-wrapper
      +b.slider-block.relative
        ui-slider(
          :speed="500"
          ref='slider'
          :dots="true"
          :slides-to-show="1"
          :rtl="isRtl"
          @slide:change='setActive'
        )
          slot(name='slides')
</template>

<script>
import { ref, onMounted, watchEffect } from 'vue'
export default {
  props: {
    isRtl: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentSlide: 1,
      resp: [
        {
          'breakpoint': 1800,
          'settings': {
            "slidesToShow": '1',
          }
        },
        {
          'breakpoint': 768,
          'settings': {
            "slidesToShow": '4',
            "navButtons": true
          }
        },
        {
          'breakpoint': 564,
          'settings': {
            "slidesToShow": '3',
          }
        }
      ],
      stepsClass: ["first", "second", "third"]
    }
  },
  setup() {
    const slider = ref(null)
    const goTo = i => {
      slider.value.goTo(i)
    }
    return {
      slider,
      goTo,
    }
  },

  methods: {
    setActive({ currentSlide }) {
      this.currentSlide = currentSlide
    },
  }
 
}

</script>