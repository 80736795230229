import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "languages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_multiselect, {
      class: "lang-style",
      "onUpdate:modelValue": [
        $setup.setLanguage,
        _cache[0] || (_cache[0] = ($event: any) => (($setup.selected) = $event))
      ],
      options: $setup.props.langsData,
      searchable: false,
      "track-by": "name",
      modelValue: $setup.selected,
      "label-by": 'name',
      "allow-empty": false,
      placeholder: $setup.selected.name
    }, null, 8, ["options", "modelValue", "placeholder"])
  ]))
}