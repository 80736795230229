<template lang="pug">
include /mixins

+b.A.link(
  v-if='route.meta && route.meta.logout'
  href=''
  @click.prevent='logout'
)
  +b.ds-caption.--size_2xs.--color_white.--medium {{ $t('Выйти') }}
+b.A.link(
  v-else-if='isAuth && !route.meta && !route.meta.logout'
  :href='prefixLanguage("/ru/cabinet/personal/")'
)
  +b.ds-caption.--size_2xs.--color_white.--medium {{ $t('В личный кабинет') }}
  pre {{ route.meta }}
+b.A.link(
  :href='url'
  v-else=''
)
  +b.ds-caption.--size_2xs.--color_white.--medium {{ $t('Войти') }}
</template>

<script setup>

import { defineProps, toRefs, ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { storage } from '@/utils/session'
import {
  HQ_VPN_ACCESS_TOKEN_STORAGE_NAME,
  HQ_VPN_IS_AUTH_STORAGE_NAME,
} from '@/const'

import { useConfirm } from '@/composables/useConfirm'
import useGlobal from '@/composables/useGlobal'
import useLogout from '@/composables/useLogout'

import {
  prefixLanguage,
} from '@/utils/urls.js'

const props = defineProps(['url'])

const { url } = toRefs(props)
const route = useRoute()

const { $t } = useGlobal()

const isAuth = computed(() => storage.getItem(HQ_VPN_IS_AUTH_STORAGE_NAME))

const logoutAndRedirect = async() => {
  try {
    await useLogout()
    window.location.href = '/'
  } catch (e) {
    console.log(e);
  }
}

const logout = async () => {
  const question = $t('Вы действительно хотите выйти?')
    try {
      await useConfirm(question)
      
      await logoutAndRedirect()
    } catch (e) {
      console.log(e.json())
    }
}

</script>