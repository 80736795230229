import { App } from 'vue'

import { Form, Field, ErrorMessage, defineRule } from '.'
import {
  required,
  email,
  confirmed,
  min,
} from './rules'

/* eslint-disable */
export default {
  install(app: App<Element>) {
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeError', ErrorMessage)

    defineRule('required', required)
    defineRule('email', email)
    defineRule('confirmed', confirmed)
    defineRule('min', min)
  }
}
