import { App } from 'vue'
import PaymentCheckStatus from './PaymentCheckStatus.vue'
import Success from './Success.vue'
import Cancel from './Cancel.vue'

const register = (app: App<Element>): void => {
  app
  .component('PaymentCheckStatus', PaymentCheckStatus)
  .component('Success', Success)
  .component('PaymentCancel', Cancel)
}

export default {
  register,
}
