import { App } from 'vue'

import AuthProvider from './Provider.vue'
import AuthView from './View.vue'

const register = (app: App<Element>): void => {
  app
    .component(`AuthProvider`, AuthProvider)
    .component(`AuthView`, AuthView)
}

export default {
  register,
}
