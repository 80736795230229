const event = 'CompletePayment'

export const sendCompletePayment = (subtype: string, paytype: string): void => {
  if (!window.isAnalyticEnabled) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event,
    subtype,
    paytype,
  })
}
