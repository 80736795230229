import {
  createSender,
  createReceiver,
} from '@/resource/resource'

import {
  prefixAPI,
  API_EXTERNAL_URL,
} from '@/resource/api'

const MODEL = 'subscriptions'

// if external Alex API add third argument API_EXTERNAL_URL
// else leave empty
// const AUTHENTICATE_LINK = prefixAPI('URL, MODEL, BASE) = BASE + MODEL + URL

const SUBSCRIPTIONS_LIST_LINK = prefixAPI('/renewal/', MODEL, API_EXTERNAL_URL)

export const subscriptionsList = createReceiver(SUBSCRIPTIONS_LIST_LINK)

