<script>
import { defineComponent } from 'vue'
import { storage } from '@/utils/session'
import {
  HQ_VPN_IS_AUTH_STORAGE_NAME,
} from '@/const'

export default defineComponent({
  name: 'AuthProvider',
  setup(props, { slots }) {
    const isAuth = storage.getItem(HQ_VPN_IS_AUTH_STORAGE_NAME)
    if (!isAuth) window.location.href = window.loginPage
    return () => slots.default({
      
    })
  },
})
</script>
