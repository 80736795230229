// convert a Unicode string to a string in which
// each 16-bit unit occupies only one byte
function toBinary(string) {
  const codeUnits = Uint16Array.from(
    { length: string.length },
    (element, index) => string.charCodeAt(index)
  );
  const charCodes = new Uint8Array(codeUnits.buffer);

  let result = "";
  charCodes.forEach((char) => {
    result += String.fromCharCode(char);
  });
  return result;
}

function fromBinary(binary) {
  const bytes = Uint8Array.from({ length: binary.length }, (element, index) =>
    binary.charCodeAt(index)
  );
  const charCodes = new Uint16Array(bytes.buffer);

  let result = "";
  charCodes.forEach((char) => {
    result += String.fromCharCode(char);
  });
  return result;
}

export type Q = { _state: string }

export const STATE = {
  parse(binary: string): any {
    return JSON.parse(fromBinary(atob(binary)))
  },
  stringify(state: any): string {
    return btoa(toBinary(JSON.stringify(state)))
  },
  makeQ(state: any): Q {
    return { _state: this.stringify(state) }
  }
}