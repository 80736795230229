<script>
import { defineComponent } from 'vue'
import { $vfm } from 'vue-final-modal'

export default defineComponent({
  name: 'ModalTrigger',
  props: {
    component: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
    },
    videourl: {
      type: String,
    },
    classes: {
      type: Array,
    },
    initial: {},
    clickToClose: {
      default: true,
    },
  },
  setup(props, { slots }) {
    const modalOpener = () => $vfm.show({
      component: 'UiModalsContainer', 
    }, {
      ...props,
    })

    return () => slots.default({
      open: () => {
        modalOpener()
      },
    })
  },
})
</script>
