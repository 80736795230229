/* eslint-disable */
import { storage } from '@/utils/session'
import {
  HQ_VPN_DEVICE_UUID_STORAGE_NAME,
} from '@/const'

export function uuidv4() {
  // eslint-disable
  const n: any = [1e7]
  return (n+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const getOrCreateDeviceUuid: () => string | null = () => {
  let id: string | null = storage.getItem(HQ_VPN_DEVICE_UUID_STORAGE_NAME)
  if (!id) {
    id = uuidv4()
    storage.setItem(HQ_VPN_DEVICE_UUID_STORAGE_NAME, id)
  }
  return id
}