import {
  createSender,
  createSimpleSender,
} from '@/resource/resource'

import {
  prefixAPI,
  API_EXTERNAL_URL,
  API_BASE_URL,
} from '@/resource/api'

const MODEL = 'token'
const AUTH_MODEL = '2factor'
const FEEDBACK_MODEL = 'feedback'
const CLIENT_MODEL = 'clients'

// if external Alex API add third argument API_EXTERNAL_URL
// else leave empty
// const AUTHENTICATE_LINK = prefixAPI('URL, MODEL, BASE) = BASE + MODEL + URL

const AUTHENTICATE_LINK = prefixAPI('/request-confirmation/', AUTH_MODEL, API_EXTERNAL_URL)
const PASSWORD_RESET_LINK = prefixAPI('', 'reset-password',  API_EXTERNAL_URL)
const PASSWORD_RESET_CONFIRM_LINK = prefixAPI('', 'reset-password-confirm{?uidb64,token}',  API_EXTERNAL_URL)
const AUTHENTICATE_CONFIRM_LINK = prefixAPI('/confirm/', MODEL, API_EXTERNAL_URL)
const AUTH_PASSWORD_CONFIRM_LINK = prefixAPI('', 'token/obtain/password/', API_EXTERNAL_URL)
const REFRESH_TOKEN_LINK = prefixAPI('/refresh/', MODEL, API_EXTERNAL_URL)
const OBTAIN_LINK = prefixAPI('/obtain/{type}/', MODEL, API_EXTERNAL_URL)
const EMAIL_CHANGE_LINK = prefixAPI('/own/change-email/', CLIENT_MODEL, API_EXTERNAL_URL)

const FEEDBACK_LINK = prefixAPI('feedback/create/')

export const authenticateCreate = createSender(AUTHENTICATE_LINK)
export const authenticateConfirm = createSender(AUTHENTICATE_CONFIRM_LINK)
export const authenticatePasswordConfirm = createSender(AUTH_PASSWORD_CONFIRM_LINK)
export const refreshToken = createSender(REFRESH_TOKEN_LINK)
export const passwordReset = createSender(PASSWORD_RESET_LINK)
export const passwordConfirm = createSender(PASSWORD_RESET_CONFIRM_LINK)
export const authResource = createSender(OBTAIN_LINK)
export const emailChangeResource = createSender(EMAIL_CHANGE_LINK)

export const getFeedBack = createSender(FEEDBACK_LINK)

