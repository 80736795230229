import {
  createSender,
  createSimpleSender,
} from '@/resource/resource'

import {
  prefixAPI,
  API_EXTERNAL_URL,
} from '@/resource/api'

const MODEL = '{type}/'

// if external Alex API add third argument API_EXTERNAL_URL
// else leave empty
const TRIAL_ACCESS_LINK = prefixAPI(MODEL, 'orders/create/',  API_EXTERNAL_URL)

export const getTrialAccess = createSender(TRIAL_ACCESS_LINK)
