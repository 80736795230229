<template lang="pug">
include /mixins.pug

+b.d-control-input.--appearance_bordered
  +e.INPUT.element(
    v-bind="$attrs"
    autocomplete="off"
    :type="inputType"
  )
  +b.change-type(
    v-if='isPasswordField'
    @click='toggleType()'
  )
    component(:is="inputType === p ? 'IEye' : 'IEyeOff'")

</template>

<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
})

const p = 'password'
const t = 'text'

const inputType = ref(props.type)

/* eslint-disable */
const isPasswordField = inputType.value === p

const toggleType = () => inputType.value = inputType.value === p ? t : p


</script>
