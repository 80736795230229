import { storage } from '@/utils/session'
import {
  HQ_VPN_ACCESS_TOKEN_STORAGE_NAME,
  HQ_VPN_REFRESH_TOKEN_STORAGE_NAME,
} from '@/const'
import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'
import useDeviceAuthentication from '@/composables/useDeviceAuthentication'

export default async function useAuthToken(resource, access?:string|null, refresh?: string) {
  if (!access) access = storage.getItem(HQ_VPN_ACCESS_TOKEN_STORAGE_NAME) || ''
  if (!access) {
    const result = (await useDeviceAuthentication())
    access = result.access
    refresh = result.refresh
  }

  storage.setItem(HQ_VPN_ACCESS_TOKEN_STORAGE_NAME, access)
  if (refresh) storage.setItem(HQ_VPN_REFRESH_TOKEN_STORAGE_NAME, refresh)

  const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
    'Authorization': `Bearer ${access}`
  }))
  return resource.middleware(HEADERS_MIDDLEWERE, 600)
}