/* eslint-disable */
/**
 * get cookie
 * @param {*} name
 */
 export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

const bakeSessionCookie = (name, value, resolve) => {
  let v = typeof value !== 'string' ? JSON.stringify(value) : value 
  const cookie = [name, '=', v, ';expires=Session', '; path=/;'].join('')
  document.cookie = cookie
  resolve(true)
}

const bakeCookie = (name, value, hours) => {
  let date = new Date()
  date = addHours(date, hours)
  let v = typeof value !== 'string' ? JSON.stringify(value) : value 
  const cookie = [name, '=', v, ';expires', '=', date.toUTCString(), '; path=/;'].join('')
  document.cookie = cookie
}
const ONE_MONTH_HOURS_COUNT = 30 * 1 * 24

/**
 * set cookie without expired date
 * @param {*} name
 * @param {*} val
 */
export function setCookie(name, val) {
  // if (this.getCookie(name) ) return
  return new Promise((resolve, reject) => {
    bakeSessionCookie(name, val, resolve)
  })
}
// export function setCookie(name, val, hours = ONE_MONTH_HOURS_COUNT) {
//   // if (this.getCookie(name) ) return
//   bakeCookie(name, val, hours)
// }
