<template lang="pug">
include /mixins.pug

+b.d-control-input
  +e.VUE-MULTISELECT.element(
    v-bind="$attrs"
    :options="options"
    :label="labelBy"
    v-model="selected"
  )
    template(
      v-slot:[name]="binded"
      v-for="(name, key) in eSlots"
    )
      slot(
        v-bind="binded"
        :name="key"
      )
      

</template>

<script>
import VueMultiselect from "vue-multiselect"
/* eslint-disable */
const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  components: {
    VueMultiselect,
  },
  emits: ['input'],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    eSlots() {
      return getESlots([...Object.keys(this.$slots)])
    },

  },
}

</script>
