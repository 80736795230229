<template lang="pug">
include /mixins.pug
+b.modal
  a.close(type='button' @click="close")
    IClose
  +e.wrapper
    +e.body
      +b.P.ds-caption.--color_main-dark.--size_3md.--size_lg-lg.--semibold.--appearance_center.ec-space_pt--6.--4-lg(v-if="info.title") {{ info.title }}
      +b.P.ds-caption.--color_main-dark.--size_md.--regular.--appearance_center.ec-space_pt--5(v-if="info.additionalInfo") {{ info.additionalInfo }}
      +b.P.ds-caption.--color_main-dark.--size_md.--regular.--appearance_center.ec-space_pt--5(v-if="info.text") {{ info.text }}
      +b.ds-caption.P #[br]
      +b.g-row--appearance_spaced
        +b.g-cell.g-cols--6
          +b.A.hq-btn--green.--full_width-till-sm.link-button.--style_main.--color_pink.--hover_blue.--full-size(
            @click.prevent="promise(true), close()"
          )
            +e.text.P.ds-caption.--color_white.--size_sm.--size_md-lg.--semibold  {{ $t('Да') }}

        +b.g-cell.g-cols--6
          +b.A.hq-btn--blue.--full_width-till-sm.link-button.--style_main.--br_pink.--hover_pink.--full-size(
            @click.prevent="promise(false), close()"
          )
            +e.text.P.ds-caption.--color_white.--size_sm.--size_md-lg.--semibold  {{ $t('Нет') }}

</template>

<script setup>
import { defineProps } from 'vue'

/* eslint-disable */
const props = defineProps({
  close: Function,
  info: Object,
  promise: Function || Promise,
})

</script>
