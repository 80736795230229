
import VueMultiselect from "vue-multiselect"
/* eslint-disable */
const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})
// @ts-ignore
export default {
  components: {
    VueMultiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
    },
    value: [Array, Object],
  },
  data() {
    return {
      model: this.value,
    }
  },
  watch: {
    value: {
      handler() {
        this.model = this.value
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    eSlots() {
      // @ts-ignore
      return getESlots([...Object.keys(this.$slots)])
    },
  },
}

