const DEVICES_LIST = {
  bsd: ['BSD'],
  android: ['Android'],
  ios: ['iPhone', 'iPad', 'iPod'],
  windows: ['Windows'],
  macos: ['Mac OS'],
  unix: ['x11'],
  webos: ['webOs'],
  tizen: ['Tizen'],
  harmonyos: ['HarmonyOS'],
  linux: ['Linux'],
}
export function getOs(): string {
  let os = ''
  const { userAgent } = window.navigator
  Object.keys(DEVICES_LIST).forEach(key => {
    DEVICES_LIST[key].forEach(r => {
      const regexp = new RegExp(`${r}`, 'img')
      const result = regexp.exec(userAgent)
      if (result) os = key
    })
  })
  return os
}